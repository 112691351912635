.temuan-patroli-content{
    background-color: #fff;
    padding: 10px;
}

.footers-modal{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* justify-content: flex-end; */
    padding: 1.25rem;
    border-top: 0 solid #e9ecef;
    border-bottom-right-radius: 0.4375rem;
    border-bottom-left-radius: 0.4375rem;
}

.txt-rad-btn{
    padding: 4px 5px;
    font-size: 14px;
    line-height: 19px;
}

.input-rad-btn[type=radio]{
    
}

.input-rad-btn {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border-radius: 50%;
    width: 16px;
    height: 16px;

    border: 2px solid #999;
    transition: 0.2s all linear;
    margin-right: 5px;

    position: relative;
    top: 4px;
}
.input-rad-btn:checked {
    border: 6px solid #6174DD;
    outline: unset !important /* I added this one for Edge (chromium) support */
}

.wrap-head-laporan{
    max-width: 400px;
    width: 26%;
}

.wrap-head-laporan img{
    width: 75px;
    height: 54px;
    margin: 10px 0 0 2px;
}