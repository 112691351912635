.containerlogin {
	height: 100vh;
	position: relative;
    /* background: url(../../assets/LoginBG.svg) center center no-repeat; */
    /* background: linear-gradient(180deg, #7980EE 0%, #77DBDC 100%); */
    background: url(../../../../assets/img/Login.svg) center center no-repeat;
    background-position-y: center !important;
    background-position-x: center !important;
	background-size: cover;
	background-position: 0 2%;
	justify-content: center !important;
	align-items: center !important;
	overflow: hidden !important;
    margin: 0px !important;
}

.tit-login-card{
    color: #2C3147;
    font-weight: 600;
    display: block;
    padding: 2px 2px;
    font-size: 16px;
    margin: 20px 0 2px 0;
}

.cardErrorShow{
    visibility: visible;
    opacity: 1;
    transition: opacity 1s linear;
    border-radius: 10px !important;
    max-width: 90% !important;
    padding-left: 5% !important;
    padding-right: 5% !important;
    width: fit-content !important;
    margin-top: 10px !important;
    background-color: #DC143C !important;
}

.cardErrorHide{
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s linear;
    border-radius: 10px !important;
    max-width: 90% !important;
    padding-left: 5% !important;
    padding-right: 5% !important;
    width: fit-content !important;
    margin-top: 10px !important;
    background-color: #DC143C !important;
}

.errorMessageLogin{
    color: white !important;
    text-align: center !important;
    white-space: pre-line !important;
}

.containerlogin:after {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	/* background: rgba(255, 255, 255, 0.571); */
	z-index: 2;
}

.txtLoginpages {
    display: flex !important;
    flex-direction: column !important;
	align-items: center !important;
	justify-content: center !important;
	z-index: 4 !important;
    margin-top: -30% !important;
    margin-left: 10% !important;
}

.txtlog {
    margin-top: 20px !important;
    font-family: 'GothamHTF';
    display: block !important;
    text-shadow: 4px 4px 4px #953DF4 !important;
	color: white !important;
	font-size: 70px;
    width: 100% !important;
    margin-left: 5%;
    z-index: 4 !important;
    white-space: pre-line !important;
    margin-bottom: 30px !important;
    line-height: 77px;
}

.txtlog2nd {
    display: block !important;
	color: white !important;
	font-size: 22px !important;
    width: 100% !important;
    margin-left: 5%;
    z-index: 4 !important;
    white-space: pre-line !important;
    letter-spacing: 0.1em;
}

.imgLog {
	display: block !important;
    margin-top: 10px !important;
    width: 100% !important;
    margin-left: 5%;
    z-index: 4 !important;
    white-space: pre-line !important;
}

.ctnLoginpages {
	display: inline-flex !important;
	align-items: center !important;
	justify-content: center !important;
	width: 10% !important;
	margin-top: -4% !important;
	overflow: hidden !important;
}

.wrapps {
	/* width:00%; */
    overflow: hidden !important;
    background-color: #fff;
    border-radius: 10px !important;
}

.loginform {
	max-width: 300px;
	background-color: #999;
	z-index: 999 !important;
	margin-top: 100% !important;
	padding: 30px !important;
}

.buttonSocWrap{
    margin-top: 5px !important;
}

.buttonSoc{
    width: 100% !important;
    background-color: white !important;
    color: black !important;
    border-radius: 10px !important;
    margin-top: 10px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.iconSoc{
    display: inline;
    margin-right: 15px !important;
}

.wrpPages {
	width: 100%;
    z-index: 999;
    padding: 40px 0;
}

.inputUname{
    background-color: white !important;
    border-radius: 10px !important;
    margin-top: 30px !important;
    width: 60% !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.registers{
    background-color: white !important;
    border-radius: 10px !important;
    width: 60% !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.wrap-pass{
    width: 60% !important;
    margin-top: 4% !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.inputPass{
    /* border: none !important; */
    background-color: white !important;
    border-bottom-left-radius: 10px !important;
    border-top-left-radius: 10px !important;
    border-right: none !important;
    /* width: 60% !important; */
}

.buttonEye{
    color: white !important;
    display: flex !important;
    justify-content: center !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    align-items: center !important;
    background-color: white !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border: 1px solid #ced4da !important;
    border-left: none !important;
}

.iconEye {
	display: inline;
}

.iconEye:hover {
	color: #00fcb6;
	cursor: pointer;
}

.btnSubmit {
    /* background: linear-gradient(180deg, #953DF4 0%, #6A28D1 100%); */
    background: #7980EE !important;
    border-radius: 10px !important;
    color: white !important;
	/* border: none !important; */
    margin-top: 20px !important;
    width: 280px !important; 
    margin-left: auto !important;
    margin-right: auto !important;
}

.btnSubmitSpin{
	background: linear-gradient(180deg, #953DF4 0%, #6A28D1 100%);
    border-radius: 10px !important;
    color: white !important;
	border: none !important;
	margin-top: 20px !important;
	width: 100% !important;
}

/* .forgotPass {
    margin-top: 10px !important;
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
    width: 100% !important;
    color: white !important;
}

.forgotPass a {
	cursor: pointer;
	color: white !important;
	text-decoration: none !important;
}

.forgotPass a:hover {
	color:#FFE98D !important;
} */

.divid {
    color: #000 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 10px !important;
}

.secTit {
    font-size: 24px;
    color: #000 !important;
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 4% !important;
}

.Regist {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 20px !important;
    color: white !important;
}

.wrap-forgot{
    width: 70%;
    text-align: center;
    margin: 10px auto 0 auto;
}

.forgotPass{
    /* display: flex !important; */
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 4% !important;
    /* margin-bottom: 10% !important; */
    color: white !important;
}

.signUpStyle {
    margin-left: 5px !important;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    color: #7980EE !important;
    font-size: 14px;
}

@media only screen and (min-device-width: 280px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
	.containerlogin {
		display: block !important;
		width: none !important;
		justify-content: center !important;
		height: fit-content !important;
    }
    .wrapps {
		width: 90% !important;
	}
	.txtLoginpages {
		width: 100% !important;
		margin-top: 8% !important;
        margin-left: 0% !important;
        margin-right: 0% !important;
    }
    .imgLog {
        display: block !important;
        margin-top: 10px !important;
        width: 100% !important;
        margin-left: 0% !important;
        z-index: 4 !important;
        white-space: pre-line !important;
        text-align: center !important;
    }
	.txtlog {
		display: block !important;
		width: 100% !important;
		font-size: 32px !important;
		text-align: center !important;
        padding: none !important;
        line-height: normal !important;
        margin-left: 0% !important;
    }
    
    .txtlog2nd {
        display: block !important;
        color: white !important;
        font-size: 14px !important;
        text-align: center !important;
        width: 100% !important;
        z-index: 4 !important;
        white-space: pre-line !important;
        letter-spacing: normal !important;
        margin-left: 0% !important;
    }

	.ctnLoginpages {
		display: inline-flex !important;
		align-items: center !important;
		justify-content: center !important;
		width: 100% !important;
        margin-top: 16% !important;
        margin-bottom: 10% !important;
	}
    
    .Regist {
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        margin-top: 20px !important;
        color: white !important;
        margin-bottom: 30px !important;
    }
}

@media only screen and (max-width: 768px) {
	/* For mobile phones: */
	[class*="col"] {
		display: block;
		width: 100%;
	}
	
}

@media  screen and (max-width: 1920px) {
    .wrapps {
		width: 471px;
	}
}
