/*@-webkit-keyframes tada*/
/*{*/
/*    from*/
/*    {*/
/*        -webkit-transform: scale3d(1, 1, 1);*/
/*        transform: scale3d(1, 1, 1);*/
/*    }*/

/*    10%,*/
/*    20%*/
/*    {*/
/*        -webkit-transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg);*/
/*        transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg);*/
/*    }*/

/*    30%,*/
/*    50%,*/
/*    70%,*/
/*    90%*/
/*    {*/
/*        -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);*/
/*        transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);*/
/*    }*/

/*    40%,*/
/*    60%,*/
/*    80%*/
/*    {*/
/*        -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);*/
/*        transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);*/
/*    }*/

/*    to*/
/*    {*/
/*        -webkit-transform: scale3d(1, 1, 1);*/
/*        transform: scale3d(1, 1, 1);*/
/*    }*/
/*}*/

/*@keyframes tada*/
/*{*/
/*    from*/
/*    {*/
/*        -webkit-transform: scale3d(1, 1, 1);*/
/*        transform: scale3d(1, 1, 1);*/
/*    }*/

/*    10%,*/
/*    20%*/
/*    {*/
/*        -webkit-transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg);*/
/*        transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg);*/
/*    }*/

/*    30%,*/
/*    50%,*/
/*    70%,*/
/*    90%*/
/*    {*/
/*        -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);*/
/*        transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);*/
/*    }*/

/*    40%,*/
/*    60%,*/
/*    80%*/
/*    {*/
/*        -webkit-transform: rotate3d(0, 0, 1, -10deg);*/
/*        transform: rotate3d(0, 0, 1, -10deg);*/
/*    }*/

/*    to*/
/*    {*/
/*        -webkit-transform: scale3d(1, 1, 1);*/
/*        transform: scale3d(1, 1, 1);*/
/*    }*/
/*}*/

/*.notif-tada*/
/*{*/
/*    -webkit-animation: tada 1.5s ease infinite;*/
/*    animation: tada 1.5s ease infinite;*/
/*}*/
/*.notif-tada-hover:hover*/
/*{*/
/*    -webkit-animation: tada 1.5s ease infinite;*/
/*    animation: tada 1.5s ease infinite;*/
/*}*/

.rows {
    display: flex;
    flex-wrap: wrap;
    margin-right: -46px;
    margin-left: -46px;
}

.header-main {
    box-shadow: 2px 2px solid #999;
}

.header-body {
    margin-bottom: 40px;
}

.max-width {
    width: 200px !important;
}

.list-dashboard {
    padding: 10px 6px 60px 6px;
}

.first-card {
    /* background-color: #FA9C54; */
    background-image: linear-gradient(#fa9c54, #f38c3d);
    border-radius: 12px;
}

.second-card {
    /* background-color: #9254FF; */
    background-image: linear-gradient(#9254ff, #7732f1);
    border-radius: 12px;
}

.third-card {
    /* background-color: #29D4D2; */
    background-image: linear-gradient(#29d4d2, #099b96);
    border-radius: 12px;
}

.fourth-card {
    /* background-color: #4D8BFF; */
    background-image: linear-gradient(#4d8bff, #2459e5);
    border-radius: 12px;
}

.text-card {
    color: #fff;
}

.text-card-value {
    font-size: 32px;
    color: #fff;
}

.nav-link-text-side {
    color: #fff;
    font-weight: 600;
}

.text-title {
    color: #999;
    font-weight: 600;
    display: block;
}

.text-active {
    color: #000;
    font-weight: 600;
    font-size: 24px;
}

.txt-head-table {
    font-weight: 600;
    padding: 6px 40px 0 0;
}

.txt-label-pages {
    color: #999;
    display: block;
}

.txt-name-personal {
    display: flex;
    justify-content: center;
}

.txt-modal-edit-client {
    margin-top: 28px;
    margin-left: 10px;
}

.eval-content {
}

.wrap-txt-eval {
    width: 50px;
    position: relative;
}

.txt-modal-eval {
    margin-top: 16px;
    margin-left: 10px;
    text-align: left;
}

.txt-modal-eval span {
    display: block;
}

.wrap-text-label-head {
    margin: 20px 0 6% 40px;
}

.link-to {
    color: #000;
}

.link-to:active {
    color: #000;
}

.link-to:hover {
    color: #999;
    transition: 1s;
}

.btn-head-table {
    padding: 5px 15px;
    border: none;
    border-radius: 4px;
    background-color: #6174dd;
    color: #fff;
    outline: none;
    font-size: 12px;
}

.btn-submit-modals {
    background-color: #6174dd;
    color: #fff;
    padding: 6px 18px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
}

.btn-cancel-modals {
    color: #6174dd;
    padding: 6px 18px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
}

.btn-head-table .txt-lbl-btn-head {
    font-weight: 600;
}

.btn-download {
    background-color: #1b2b4b;
    border-radius: 4px;
    padding: 5px 15px;
    border: none;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    margin: 0 4px;
}

.btn-modal-wrapper {
    justify-content: flex-start !important;
}

.btn-on-profile {
    padding: 10px;
    border: none;
    outline: none;
    width: 100%;
    color: #6174dd;
    background-color: #ffffff;
    border-radius: 8px;
}

.wrap-btn-modal-footer {
    margin: 10px 30px 20px 30px;
}

.btn-modal {
    padding: 10px 50px;
    background-color: #6174dd;
    color: #fff;
}

.sec-list-tab {
    margin: 20px 80px 0 0;
    background-color: #fbfcff;
}

.sub-col-tabs-list {
    display: block;
    margin-left: -18px !important;
    font-size: 12px;
    width: 70%;
}

.sub-col-tabs-list .sub-text-tabs-list {
    display: block;
    font-weight: 600;
}

.sub-col-tabs-list .sub-sec-tabs-list {
    display: block;
    color: #999;
}

.sub-col-tabs-list .img-list {
    display: block;
    margin-bottom: 18px;
}

.actives {
    background-color: rgb(255, 255, 255) !important;
    padding: 4px 18px;
    color: #000;
}

.dropdown-button {
    background-color: #f7f9fc !important;
    border: none !important;
    color: #6174dd;
    font-size: 12px;
    box-shadow: none !important;
}

.label-dropdown-text {
    font-size: 12px !important;
}

.img-size-list-table {
    width: 50px;
    margin: 10px 10px;
}

.img-size-list-table img {
    width: 50px;
}

.EditRow {
    padding: 6px 0px 10px 30px !important;
}

.table-button {
}

.icon-tables {
    color: #6174dd;
    font-weight: 600;
}

.icon-tables i {
    width: 0px;
    height: 20px;
}

.icons-table {
    padding: none !important;
    display: table-cell;
    padding: 10px 10px 0 10px !important;
}

.table-style {
    overflow-x: none !important;
    display: block;
    width: 100%;
}

.image-dashboard {
    width: auto;
    height: auto;
    padding: 40px 10px 10px 10px;
}

.image-dashboard img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.wrap-form {
    margin: 2em auto;
}

.wrap-rad-btn {
    max-width: 50%;
}

.btn-tambah-pengguna {
    background-color: #6174dd;
    color: #fff;
    border: none;
    padding: 10px 40px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
}

.btn-filter {
    padding: 5px 15px;
    border: none;
    border-radius: 4px;
    background-color: #1b2b4b;
    color: #fff;
    outline: none;
    font-size: 12px;
    margin: 0 10px 0 0;
}

.btn-tambah-client {
    border: none;
    border-radius: 4px;
    padding: 4px 18px;
    color: #fff;
    background-color: #6174dd;
    font-weight: 600;
}

.wrapp-table {
    background-color: #fff !important;
    color: transparent;
    margin: -86px -44px 0 -44px;
}

.tab-content {
    background-color: #fff !important;
}

.tab-pane {
    margin-bottom: -16px !important;
}

.txt-head-label-laporan {
    font-size: 26px;
}

.wrap-label-laporan {
    padding: 18px 0 0 0;
}

.txt-lbl-sub {
    font-size: 12px;
}

.img-tables {
    display: flex;
}

.img-tables img {
    width: 50px;
    height: 50px;
}

.wrap-profile-card {
    padding: 90px 20px 0 20px;
}

.wrap-label-profile-detail {
    display: flex;
    justify-content: center;
    background-color: #f5f6f9;
    padding: 10px;
    margin-top: 10px;
}

.wrap-label-email-detail {
    display: flex;
    justify-content: center;
    background-color: #f5f6f9;
    padding: 10px;
    margin-top: 10px;
}

.wrap-label-place-detail {
    display: flex;
    justify-content: center;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
}

.wrap-label-profile-detail i {
    margin-right: 16px;
    -ms-transform: rotate(90deg); /* IE 9 */
    transform: rotate(90deg);
}

.wrap-label-email-detail i {
    margin-right: 16px;
    margin-top: 5px;
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg);
}

.wrap-label-place-detail i {
    margin-right: 12px;
}

#tableClient th:nth-child(1) {
    width: 182px;
}

.wrap-content-edit-modal {
    margin: 10px 0 20px 0;
    text-align: center;
}

.img-modal-edit-client {
    margin: 10px 0 10px 10px;
}

.img-modal-edit-client img {
    width: 60px;
    height: 60px;
}

.page-link page-link span {
    display: none !important;
}

.wrap-view-item-img {
    width: 46px;
    /* height: 20px; */
}

.wrap-content-view-item {
    display: flex;
    justify-content: center;
    background-color: #f3f5fb;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    border-radius: 8px;
}

.wrap-head-modal-table {
    display: flex;
}

.lbl-avatar-view-item {
    padding: 10px;
    margin-left: 20px;
    margin-top: 8px;
}

.avatars {
    color: #fff;
    background-color: #adb5bd;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    border-radius: 0.375rem;
    height: 48px !important;
    width: 48px !important;
}

.coloumn-table-head {
    display: flex !important;
    justify-content: flex-end !important;
}

.datepicks {
    font-size: 14px;
    border: 1px solid #999;
    text-align: center;
    color: #6174dd;
    font-weight: 600;
}
.datepicks-default {
    font-size: 12px;
    border: 1px solid #dfe2e6;
    color: #949494;
    font-weight: 400;
    width: 137px;
    height: 37px;
    border-radius: 5px;
    padding-left: 5px;
}

.btn-download-custom {
    width: 332px;
    height: 37px;
    left: 556px;
    top: 607px;
    background: #e6e6e6;
    border-radius: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #b9b6b6;
    cursor: pointer;
}

.btn-download-custom-active {
    width: 332px;
    height: 37px;
    left: 556px;
    top: 607px;
    background: #6173dd;
    border-radius: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
}

.wrap-head-table {
    width: 29%;
}

.wrap-pages {
    margin: 20vh 60px;
}

.wrap-datepick {
    width: 22%;
    /* border: 1px solid #999; */
}

.wrap-datepick .react-datepicker-wrapper {
    border: none !important;
    border-radius: 6px;
    width: 120px !important;
    /* padding-left: 10px; */
    background-color: #f7f9fc;
}

.wrap-datepick .react-datepicker-wrapper .react-datepicker__input-container {
    border: none !important;
}

.wrap-datepick
    .react-datepicker-wrapper
    .react-datepicker__input-container
    input {
    border: none !important;
    cursor: pointer;
    width: 100px;
    background-color: #f7f9fc;
    padding: 8px 0;
}

.btn-pindah-lokasi {
    background-color: #6174dd;
    color: #fff;
    border: none;
    padding: 10px 40px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
}

.btn-ubah-jabatan {
    background-color: #1b2b4b;
    color: #fff;
    border: none;
    padding: 10px 40px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
}

.wrap-body-content {
    padding: 10px;
}

.wrp-btn-modals {
    /* margin-top: 10px; */
}

.btn-edit-profile {
    color: #6174dd;
    margin: 0 !important;
    padding: 4px 10px !important;
}

.btn-back-modal {
    border: none !important;
    box-shadow: none !important;
    color: #6174dd;
    outline: none !important;
}

.wrp-header-label {
    margin-bottom: 10vh;
}

.wrp-imge-pindah {
    padding: 10px;
}

.img-uploads {
    top: 31%;
    position: absolute;
    left: 27.1vh;
    background-color: rgba(102, 102, 102, 0.172);
    width: 90px;
    border-radius: 50%;
    /* border-bottom-left-radius: 120px 154px;
    border-bottom-right-radius: 120px 154px; */
    border-image-repeat: 100%;
    padding-bottom: 2px;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.image-uploads {
    top: 31%;
    transform: translateY(-20px);
}

.icon-cameras {
    color: #fff !important;
}

.wrp-value-table {
    display: block;
    padding: 10px 0 0 0;
    /* text-align: center; */
}

.wrp-kelengkapan-image {
    width: 50px;
}

.wrp-kelengkapan-image img {
    width: 80px;
}
/*
.footer {
    clear: both;
    position: relative;
    height: 10vh;
    bottom: 0;
} */

.logouts {
    background-color: transparent;
    border: none !important;
    margin-left: 6px;
    font-weight: 400;
    font-size: 0.9375rem;
    font-weight: 300;
}

.button-dark {
    background-color: #1b2b4b;
    padding: 10px;
    width: 100%;
    color: white;
    text-align: center;
    display: inline-block;
    font-size: 10px;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    /* padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 15px;
  padding-left: 15px; */
    color: #fff;
}

.button-xls {
    background-color: #ebffff;
    padding: 10px;
    width: 100%;
    color: #19b6b3;
    text-align: center;
    display: inline-block;
    font-size: 10px;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    /* padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 15px;
  padding-left: 15px; */
    color: #fff;
}

.map-container {
    height: 400px;
    width: 100%;
}

.overlays {
    top: 0 !important;
}

.btn-primary-default {
    position: relative;
    text-transform: none;
    transition: all 0.15s ease;
    letter-spacing: 0.025em;
    font-size: 0.875rem;
    will-change: transform;
    cursor: pointer;
}

.btn-sm {
    padding: 0.5rem 1.6rem;
    font-size: 0.64rem;
}

.dot {
    height: 11px;
    width: 11px;
    /* background-color: #54D68E; */
    border-radius: 50%;
    display: inline-block;
}

.title-text {
    color: #2e2d2d;
    font-size: 14px;
    font-weight: 600;
}

.title-sub {
    color: #949494;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
}

.content {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #2e2d2d;
}
.actives-sidebar {
    color: #f3eb24;
    background-color: #142342;
    margin: 0 10px;
    padding: 0;
    border-radius: 6px;
}

.actives-sidebar-icon {
    color: #f3eb24;
}

.button-custom {
    padding: 10px;
    width: 100%;
    color: white;
    text-align: center;
    display: inline-block;
    font-size: 10px;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    /* padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 15px;
  padding-left: 15px; */
}

.input-auth {
    background-color: white;
    /* border-radius: 5px !important; */
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 0.725rem 0.75rem;
    border: 1px solid #dee2e6;
    border-left: none;
    width: 100% !important;
    color: #8898aa;
    font-weight: 400;
    box-shadow: 0 3px 2px rgb(233 236 239 / 5%);
    background-clip: padding-box;
    /* margin-top: 30px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  line-height: 1.5;
  */
}

.input-icon {
    padding: 0.725rem 0.75rem;
    background-color: white;
    border: 1px solid #dee2e6;
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    min-width: 50px;
    text-align: center;
}

.title-register {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #2c3147;
    text-align: center;
    letter-spacing: 0.16em;
}

.button-custome {
    width: 100%;
    height: 46px;
    padding: 10px;
    background-color: #fff;
    color: #6174dd;
    border-radius: 5px;
    cursor: pointer;
}

.btn-logout {
    margin-top: 100%;
    cursor: pointer;
}
.btn-logout:hover {
    background-color: #142342;
    border-radius: 6px;
    padding: 10px 20px;
}

.dot-notif-active {
    height: 11px;
    width: 11px;
    background-color: #4682fa;
    border-radius: 50%;
    margin-right: 4%;
    display: inline-block;
}
.dot-notif-nonactive {
    height: 11px;
    width: 11px;
    background-color: transparent;
    border-radius: 50%;
    margin-right: 4%;
    display: inline-block;
}

.card-list-notif-active {
    background-color: #eef7ff;
    padding: 10px 20px;
    box-shadow: none;
}

.card-list-notif-nonactive {
    background-color: #fbfcff;
    padding: 10px 20px;
    box-shadow: none;
}

.content-notif {
    padding: 0;
    margin: 0;
}
.content-title-notif {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #2e2d2d;
}
.content-sub-notif {
    padding-top: 10px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #9b9fb7;
    margin-top: 2px;
    margin-bottom: 2px;
}

.content-clock-notif {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #949494;
}

.actives-tab {
    color: #000;
    font-weight: bold;
}

.special_modal .modal-content {
    background-color: transparent;
    border-bottom: none !important;
}

.input-date-picker-customs {
    border: 1px solid #dee2e6;
    padding: 0.625rem 0.75rem;
    color: #8898aa;
}

.input-date-picker-customs_2 {
    border: 1px solid #dee2e6;
    padding: 0.625rem 0.75rem;
    color: #8898aa;
    width: 100%;
}

.box-name {
    height: 65px;
    background: #f3f5fb;
    border-radius: 5px;
}

.box-name .name {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #2e2d2d;
}

.text-kelengkapan-active {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    color: #fa9c54;
    text-transform: uppercase;
}
.text-kelengkapan {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    color: #2e2d2d;
    text-transform: uppercase;
}

.avatar-image {
    vertical-align: middle;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.avatar-image-box {
    vertical-align: middle;
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.cursor-pointer {
    cursor: pointer;
}
